<template>
  <!--  <el-card class="box-card">-->
  <!--    <el-collapse v-model="activeName" accordion>
        <el-collapse-item name="1">
          <template slot="title">
            <i class="header-icon el-icon-search"></i> 搜索
          </template>
          <el-form :inline="true" :model="queryForm" @submit.native.prevent>
            <el-form-item label="标题:">
              <el-input
                  v-model.trim="queryForm.title"
                  clearable
                  placeholder="请输入用户名"
                  size="small"
              />
            </el-form-item>
            <el-form-item>
              <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
                查询
              </el-button>
            </el-form-item>
          </el-form>
        </el-collapse-item>
      </el-collapse>-->
  <div class="operationsmanage-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small" style="margin: 10px 0 0 20px">
          新增
        </el-button>
        <el-button type="primary" @click="jiugonggeWinningRecord" size="small">中奖纪录</el-button>
      </vab-query-form-left-panel>
      <span style="float: right">
          <el-input
              v-model.trim="queryForm.name"
              clearable
              style="width: 300px"
              placeholder="请输入活动名称"
              size="small"
          />
          <el-button icon="el-icon-search" @click="queryData" type="primary" size="small"
                     style="margin: 10px 20px 0 5px">
            查询
          </el-button>
      </span>
    </vab-query-form>
    <div style="margin: 10px 0 0 20px">
      <el-radio-group v-model="radio1" size="small">
        <el-radio-button :label="1">全部</el-radio-button>
        <el-radio-button :label="2">未开始</el-radio-button>
        <el-radio-button :label="3">进行中</el-radio-button>
        <el-radio-button :label="4">结束</el-radio-button>
      </el-radio-group>
    </div>

    <el-table
        :data="list"
        size="small"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80">
      </el-table-column>
      <el-table-column
          align="center"
          prop="title"
          label="标题">
      </el-table-column>
      <el-table-column
          align="center"
          prop="rule"
          label="规则">
      </el-table-column>
      <el-table-column align="center" label="抽奖次数">
        <template #default="{ row }">
          <span>{{ row.count }}次</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="consume"
          label="消耗积分"
      />
      <el-table-column
          align="center"
          prop="probability"
          label="概率"
      />
      <el-table-column align="center" label="参与条件">
        <template #default="{ row }">
          <span v-if="row.type == 1">消费可抽</span>
          <span v-else-if="row.type == 2">邀请可抽</span>
          <span v-else>浏览可抽</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="details"
          label="规则说明"
      />
      <el-table-column align="center" label="开始时间">
        <template slot-scope="scope">
          {{ scope.row.start_time | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="结束时间">
        <template slot-scope="scope">
          {{ scope.row.end_time | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="创建时间">
        <template slot-scope="scope">
          {{ scope.row.create_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="修改时间">
        <template slot-scope="scope">
          {{ scope.row.update_at | formatDate }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
    <!--  </el-card>-->
  </div>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      radio1: 1,
      query_time: [],
      activeName: '1',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        title: '',
      },
      current: 0,
      button_state: [
        {
          name: '全部'
        },
        {
          name: '未开始'
        },
        {
          name: '进行中'
        },
        {
          name: '结束'
        },
      ],
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
            {name: '下午'},
          ],
          merchant_id: '1',
          is_check: '0',
          color: '#00afff',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
            {name: '中午'},
          ],
          merchant_id: '1',
          is_check: '1',
          color: '#000000',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          start_at: '1647584613',
          end_at: '1647584613',
          site: [
            {name: '早上'},
          ],
          merchant_id: '1',
          is_check: '2',
          color: '#ffff00',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.current = 1;
        this.queryForm.type = 1
      } else if (num == 2) {
        this.current = 2;
        this.queryForm.type = 2
      } else if (num == 3) {
        this.current = 3;
        this.queryForm.type = 3
      } else {
        this.current = 0;
        this.queryForm.type = ''
      }
      this.fetchData()
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'jiugongge/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'jiugongge/revise',
        })
      }
    },
    // 中奖记录跳转
    jiugonggeWinningRecord() {
      this.$router.push({
        path: 'jiugonggeWinningRecord',
      })
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      const {list} = data
      for (let i = 0; i < list.length; i++) {
        list[i]['state'] = list[i]['state'].toString()
      }
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '1' ? '待回复'
          : row.state == '2' ? '已报价'
              : row.state == '3' ? '已成交'
                  : row.state == '4' ? '已拒绝'
                      : '暂无'
    },
  }
}

</script>
<style>
.tab_button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-color: #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 9px 15px;
  font-size: 13px;
  border-radius: 0;
}

.button {
  margin-top: 20px;
}
</style>
